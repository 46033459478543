@import '../../../styles/propertySets.css';

.root {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;

  background-color: var(--matterColorLight);
  border-radius: 6px;
  border-bottom: none;
  flex-basis: 60rem;
  margin-top: 3vh;

  @media (--viewportMedium) {
    flex-basis: 55rem;
    flex-grow: 0;
    /* min-height: 568px; */
    margin-top: 12.5vh;
  }

  @media (max-width: 1280px) {
    margin-bottom: 40px;
    flex-basis: 45rem;
  }
}

.modalContent {
  align-self: flex-start;
  margin-top: 2.5rem;
  padding: 4rem 5.6rem 4rem 3.6rem;

  @media (--viewportMedium) {
    align-self: initial;
    margin-top: 4vh;
    margin-bottom: 4vh;
    padding: 4rem 7rem 4rem 5rem;
  }

  @media (--viewport1280) {
    padding: 3rem 9rem 0 7rem;
  }

  @media (--viewportLarger) {
    margin-top: 6.5vh;
    margin-bottom: 6.5vh;
  }
}

.modalHeading {
  display: flex;
  align-items: center;
}

/* Title of the modal */
.modalTitle {
  color: var(--marketplaceColorNavy);
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.25;
  padding-top: 6px;

  @media (--viewport1280) {
    font-size: 2.8rem;
  }
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  color: var(--marketplaceColorNavy);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  text-transform: none;
  margin-bottom: 10px;
  margin-top: 10px;

  @media (--viewport1280) {
    font-size: 1.6rem;
  }
}

.reviewFormForm {
  @media (--viewportMedium) {
    padding-top: 2px;
  }

  @media (--viewport1280) {
    margin-top: 3.6rem;
  }
}

.reviewFormForm label {
  color: var(--marketplaceColorNavy);
  font-size: 1.4rem;
  font-weight: var(--fontWeightRegular);
  margin-bottom: 1.2rem;
  padding: 0;
  text-transform: uppercase;

  @media (--viewport1280) {
    font-size: 1.6rem;
  }

  @media (--viewportLarger) {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}

.reviewFormForm input {
  border: 2px solid var(--marketplaceColorNavy);
  color: var(--marketplaceColorNavy);
  border-radius: 6px;
  font-size: 1.3rem;
  padding: 1.2rem 1.8rem;

  @media (--viewport1280) {
    font-size: 1.6rem;
    padding: 1.6rem 2rem;
  }

  @media (--viewportLarger) {
    border-width: 3px;
    font-size: 1.8rem;
    padding: 1.8rem 2.2rem;
  }
}

.reviewFormForm ::placeholder {
  font-weight: 300;
}

.reviewFormForm button {
  @media (max-width: 1279px) {
    font-size: 1.6rem;
  }

  @media (max-width: 1499px) {
    font-size: 1.8rem;
    min-height: 6rem;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;

  flex: 0 0 47px;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-right: 20px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}
